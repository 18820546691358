export default {

  apiKey: "AIzaSyD1h6bRb_J_Dhb7OgUoWly6YmMQEEdyl9w",

  authDomain: "larrys-ac-aooh.firebaseapp.com",

  projectId: "larrys-ac-aooh",

  storageBucket: "larrys-ac-aooh.appspot.com",

  messagingSenderId: "158801681402",

  appId: "1:158801681402:web:147d46a24fff0e01102243"

};
